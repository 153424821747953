import { FishWreath } from './components/FishWreath';

function App() {
  return (
    <div className="centered-col">
      <h1>Morgan Galvin</h1>
      <div className="centered-col">
        <FishWreath />
      </div>
      <div className="row links">
        <a href="mailto:morgan@ludwigthepig.com" aria-label="Link to email">
          ✉️
        </a>
        <a
          href="https://ludwigthepig.com"
          aria-label="Link to ludwigthepig.com"
        >
          🐷
        </a>
        <a
          href="https://www.linkedin.com/in/morgangalvin/"
          aria-label="Link to LinkedIn"
        >
          🤝
        </a>
      </div>
    </div>
  );
}

export default App;
