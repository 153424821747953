import { MouseEvent, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Fish from './Fish';

const diameter = '200px';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface WreathProps {
  velocity: number;
}

const Wreath = styled.div<WreathProps>`
  margin: 0 !important;
  position: relative;
  width: 400px;
  height: 400px;
  animation: ${rotate} ${(props) => 10 / Math.max(props.velocity, 1)}s linear
    infinite;

  > svg {
    position: absolute;
    top: 25%;
    left: 25%;
    width: ${diameter};
    height: ${diameter};

    &:nth-child(1) {
      transform: rotate(0) translate(calc(${diameter} / 2)) rotate(100deg);
    }
    &:nth-child(2) {
      transform: rotate(120deg) translate(calc(${diameter} / 2)) rotate(100deg);
    }
    &:nth-child(3) {
      transform: rotate(240deg) translate(calc(${diameter} / 2)) rotate(100deg);
    }
  }
`;

/** [x, y] */
interface Coords {
  x: number;
  y: number;
}

export function FishWreath() {
  let priorCoords = useRef<Coords | null>(null);
  let curCoords = useRef<Coords | null>(null);
  let priorTime = useRef<Date | null>(null);
  let resetVelocityTimeout = useRef<any>(null);
  const [velocity, setVelocity] = useState<number>(0);

  function clearRefs() {
    curCoords.current = null;
    priorCoords.current = null;
    priorTime.current = null;

    if (!resetVelocityTimeout.current) {
      resetVelocityTimeout.current = setTimeout(() => {
        setVelocity(0);
      }, 1000);
    }
  }

  useEffect(() => {
    setInterval(() => {
      if (!curCoords.current) {
        return;
      }

      const currentTime = new Date();
      if (!priorCoords.current || !priorTime.current) {
        priorCoords.current = curCoords.current;
        priorTime.current = currentTime;
        return;
      }

      const { x: x1, y: y1 } = priorCoords.current;
      const { x: x2, y: y2 } = curCoords.current;

      // sqrt(x^2 + y^2)
      const distance = Math.sqrt(
        Math.abs(x1 - x2) ** 2 + Math.abs(y1 - y2) ** 2,
      );

      const time = currentTime.valueOf() - priorTime.current.valueOf();

      if (x1 === x2 && y1 === y2) {
        clearRefs();
        return;
      }

      priorTime.current = currentTime;
      priorCoords.current = curCoords.current;

      clearInterval(resetVelocityTimeout.current as any);
      resetVelocityTimeout.current = null;
      setVelocity((distance * time) / 3);
    }, 10);
  }, []);

  function handleEvent(e: MouseEvent) {
    // If mouse is down
    if (e.buttons > 0) {
      curCoords.current = { x: e.clientX, y: e.clientY };
    } else {
      clearRefs();
    }
  }

  function handleRandoEvent(e: any) {
    console.log({ e });
  }

  return (
    <Wreath
      velocity={velocity}
      onMouseOver={handleEvent}
      onTouchMove={handleRandoEvent}
    >
      <Fish></Fish>
      <Fish></Fish>
      <Fish></Fish>
    </Wreath>
  );
}
