export default function Fish() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 138.426 65.738"
      aria-hidden="true"
    >
      <defs>
        <filter id="a">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color" />
          <feComposite in="SourceGraphic" in2="blur" operator="out" />
          <feComposite in="color" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="b">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-2" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-2" />
          <feComposite in="SourceGraphic" in2="blur-2" operator="out" />
          <feComposite in="color-2" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="c">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-3" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-3" />
          <feComposite in="SourceGraphic" in2="blur-3" operator="out" />
          <feComposite in="color-3" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="d">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-4" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-4" />
          <feComposite in="SourceGraphic" in2="blur-4" operator="out" />
          <feComposite in="color-4" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="e">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-5" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-5" />
          <feComposite in="SourceGraphic" in2="blur-5" operator="out" />
          <feComposite in="color-5" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="f">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-6" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-6" />
          <feComposite in="SourceGraphic" in2="blur-6" operator="out" />
          <feComposite in="color-6" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="g">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-7" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-7" />
          <feComposite in="SourceGraphic" in2="blur-7" operator="out" />
          <feComposite in="color-7" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="h">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-8" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-8" />
          <feComposite in="SourceGraphic" in2="blur-8" operator="out" />
          <feComposite in="color-8" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="i">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-9" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-9" />
          <feComposite in="SourceGraphic" in2="blur-9" operator="out" />
          <feComposite in="color-9" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="j">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-10" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-10" />
          <feComposite in="SourceGraphic" in2="blur-10" operator="out" />
          <feComposite in="color-10" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="k">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-11" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-11" />
          <feComposite in="SourceGraphic" in2="blur-11" operator="out" />
          <feComposite in="color-11" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="l">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-12" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-12" />
          <feComposite in="SourceGraphic" in2="blur-12" operator="out" />
          <feComposite in="color-12" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="m">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-13" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-13" />
          <feComposite in="SourceGraphic" in2="blur-13" operator="out" />
          <feComposite in="color-13" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
        <filter id="n">
          <feOffset dx="1" dy="3" />
          <feGaussianBlur result="blur-14" stdDeviation="3" />
          <feFlood floodColor="#fafafa" floodOpacity=".345" result="color-14" />
          <feComposite in="SourceGraphic" in2="blur-14" operator="out" />
          <feComposite in="color-14" operator="in" />
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M108.754 43.339c-.78-.345.3 7.466-2.009 10.727 8.141 2.1 23.472-6.552 25.521-9.743-14.529 6.009-18.937 2.063-21.7.35-.31-.194-1.701-1.285-1.812-1.334Z"
        />
        <g filter="url(#a)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M108.754 43.34c-.78-.345.3 7.466-2.009 10.727 8.141 2.1 23.472-6.552 25.521-9.743-14.529 6.009-18.937 2.063-21.7.35-.31-.194-1.701-1.285-1.812-1.334Z"
          />
        </g>
        <path
          fill="none"
          d="M108.754 43.339c-.78-.345.3 7.466-2.009 10.727 8.141 2.1 23.472-6.552 25.521-9.743-14.529 6.009-18.937 2.063-21.7.35-.31-.194-1.701-1.285-1.812-1.334Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M110.559 32.6c-3.271 6.119-1.145 19.4-4.6 21.35-1.364.77-11.642-1.643-19.719-8.324-11.954-8-36.4-16.887-40.892-16.1 8.759-7.794 30.469-5.781 48.03-1.554 6.248 1.502 12.72 3.778 17.181 4.628Z"
        />
        <g filter="url(#b)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M110.561 32.605c-3.271 6.119-1.145 19.4-4.6 21.35-1.364.77-11.642-1.643-19.719-8.324-11.954-8-36.4-16.887-40.892-16.1 8.759-7.794 30.469-5.781 48.03-1.554 6.248 1.502 12.72 3.778 17.181 4.628Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M110.559 32.6c-3.271 6.119-1.145 19.4-4.6 21.35-1.364.77-11.642-1.643-19.719-8.324-11.954-8-36.4-16.887-40.892-16.1 8.759-7.794 30.469-5.781 48.03-1.554 6.248 1.502 12.72 3.778 17.181 4.628Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M10.206 61.821c.758-6.692 14.977-77.654 104.51-34.968a1.442 1.442 0 0 0 1.645-1.739c2.25-1.875-85.686-50.981-111.741 36.706.003.001 3.094 6.538 5.586.001Z"
        />
        <g filter="url(#c)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M10.203 61.821c.758-6.692 14.977-77.654 104.51-34.968a1.442 1.442 0 0 0 1.645-1.739c2.25-1.875-85.686-50.981-111.741 36.706.003.001 3.094 6.538 5.586.001Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M10.206 61.821c.758-6.692 14.977-77.654 104.51-34.968a1.442 1.442 0 0 0 1.645-1.739c2.25-1.875-85.686-50.981-111.741 36.706.003.001 3.094 6.538 5.586.001Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M135.791 41.47c-.829 1.161-16.7 12.067-27.3 1.438-.464-9.048 8.407-17.821 8.407-17.072 1.601.584 19.722 14.473 18.893 15.634Z"
        />
        <g filter="url(#d)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M135.793 41.468c-.829 1.161-16.7 12.067-27.3 1.438-.464-9.048 8.407-17.821 8.407-17.072 1.601.584 19.722 14.473 18.893 15.634Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M135.791 41.47c-.829 1.161-16.7 12.067-27.3 1.438-.464-9.048 8.407-17.821 8.407-17.072 1.601.584 19.722 14.473 18.893 15.634Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M137.335 43.496c-.024.463-26.587 30.785-56.609 7.488-29.751-23.088-50.159-19.929-57.458-16.281-3.759 1.809 2.5-5.593 4.954-6.877C30.733 26.243 57 27.51 90.733 48.863c24.846 16.358 44.93-8.163 45.307-7.474.703 1.283 1.319 1.644 1.295 2.107Z"
        />
        <g filter="url(#e)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M137.339 43.494c-.024.463-26.587 30.785-56.609 7.488-29.751-23.088-50.159-19.929-57.458-16.281-3.759 1.809 2.5-5.593 4.954-6.877 2.511-1.583 28.778-.316 62.511 21.037 24.846 16.358 44.93-8.163 45.307-7.474.703 1.283 1.319 1.644 1.295 2.107Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M137.335 43.496c-.024.463-26.587 30.785-56.609 7.488-29.751-23.088-50.159-19.929-57.458-16.281-3.759 1.809 2.5-5.593 4.954-6.877C30.733 26.243 57 27.51 90.733 48.863c24.846 16.358 44.93-8.163 45.307-7.474.703 1.283 1.319 1.644 1.295 2.107Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M91.677 42.38c.434 3.465-24.238 18.284-26.095 19.949-3.51.219-8.742-6.6-7.5-8.35S91.243 38.915 91.677 42.38Z"
        />
        <g filter="url(#f)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M91.676 42.382c.434 3.465-24.238 18.284-26.095 19.949-3.51.219-8.742-6.6-7.5-8.35s33.161-15.064 33.595-11.599Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M91.677 42.38c.434 3.465-24.238 18.284-26.095 19.949-3.51.219-8.742-6.6-7.5-8.35S91.243 38.915 91.677 42.38Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M110.114 32.442c-3.176 1.017-57.871-25-75.281 8.013-1.726 2.172-4.367 2.282-4.293 1.459 1.846-31.234 62.992-23.537 81.18-12.243.432.496.902 1.971-1.606 2.771Z"
        />
        <g filter="url(#g)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M110.113 32.44c-3.176 1.017-57.871-25-75.281 8.013-1.726 2.172-4.367 2.282-4.293 1.459 1.846-31.234 62.992-23.537 81.18-12.243.432.496.902 1.971-1.606 2.771Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M110.114 32.442c-3.176 1.017-57.871-25-75.281 8.013-1.726 2.172-4.367 2.282-4.293 1.459 1.846-31.234 62.992-23.537 81.18-12.243.432.496.902 1.971-1.606 2.771Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M31.619 48.42c-1.884-1.638 5.514-4.427 3.316 2.384-2.862 5.22-8.935-1.3-7.722-4.914s13.392-8.074 14.5 2.529-10.344 13.142-20.174 8.963c-2.723-1.157-5.454-7.56-4.265-15.045 3.429-9.264 26.489-47.821 95.473-15.759 3.676 1.06-.569 3.433-.53 2.97-36.989-17.846-66.713-7.413-74.639-2.409s-7.629 16.01-7.629 16.01"
        />
        <g filter="url(#h)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M31.615 48.417c-1.884-1.638 5.514-4.427 3.316 2.384-2.862 5.22-8.935-1.3-7.722-4.914s13.392-8.074 14.5 2.529-10.344 13.142-20.174 8.963c-2.723-1.157-5.454-7.56-4.265-15.045 3.429-9.264 26.489-47.821 95.473-15.759 3.676 1.06-.569 3.433-.53 2.97-36.989-17.846-66.713-7.413-74.639-2.409s-7.629 16.01-7.629 16.01"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M31.619 48.42c-1.884-1.638 5.514-4.427 3.316 2.384-2.862 5.22-8.935-1.3-7.722-4.914s13.392-8.074 14.5 2.529-10.344 13.142-20.174 8.963c-2.723-1.157-5.454-7.56-4.265-15.045 3.429-9.264 26.489-47.821 95.473-15.759 3.676 1.06-.569 3.433-.53 2.97-36.989-17.846-66.713-7.413-74.639-2.409s-7.629 16.01-7.629 16.01"
        />
      </g>
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="2"
        d="M112.78 37.242c1.214-1.767 3.5-8.611 5.354-8.007s9.458 8.137 10.4 8.007"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="2"
        d="M114.166 34.479c1.61.7 9.1 2.935 10.582-.5"
      />
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M1.049 51.728c.458-2.381 8.472-14.32 9.691-14.041s2.6 1.1 2.566 1.463-7.369 15.123-8.079 16.637-4.637-1.678-4.178-4.059Z"
        />
        <g filter="url(#i)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M1.052 51.727c.458-2.381 8.472-14.32 9.691-14.041s2.6 1.1 2.566 1.463S5.94 54.272 5.23 55.786s-4.637-1.678-4.178-4.059Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M1.049 51.728c.458-2.381 8.472-14.32 9.691-14.041s2.6 1.1 2.566 1.463-7.369 15.123-8.079 16.637-4.637-1.678-4.178-4.059Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M7.644 31.403c-.407 6.685 5.387 6.927 6.045 7.669 1.99-5.079 16.587-18.155 16.357-18.444S8.051 24.719 7.644 31.403Z"
        />
        <g filter="url(#j)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M7.642 31.405c-.407 6.685 5.387 6.927 6.045 7.669 1.99-5.079 16.587-18.155 16.357-18.444S8.049 24.721 7.642 31.405Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M7.644 31.403c-.407 6.685 5.387 6.927 6.045 7.669 1.99-5.079 16.587-18.155 16.357-18.444S8.051 24.719 7.644 31.403Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M28.757 11.625c-3.058 3.341 1.292 7.451 1.779 8.994 1.219-.611 9.612-9.4 29.157-11.121a1.442 1.442 0 0 0-1.091-2.094c-.83.001-26.788.88-29.845 4.221Z"
        />
        <g filter="url(#k)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M28.759 11.625c-3.058 3.341 1.292 7.451 1.779 8.994 1.219-.611 9.612-9.4 29.157-11.121a1.442 1.442 0 0 0-1.091-2.094c-.83.001-26.788.88-29.845 4.221Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M28.757 11.625c-3.058 3.341 1.292 7.451 1.779 8.994 1.219-.611 9.612-9.4 29.157-11.121a1.442 1.442 0 0 0-1.091-2.094c-.83.001-26.788.88-29.845 4.221Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M61.488 1.458c-2.252 1.927-2.987 5.663-1.276 7.437s4.184-2.172 31.439 4c.056-1.053.419-1.514-1.1-2.792S67.285-1.239 61.488 1.458Z"
        />
        <g filter="url(#l)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M61.49 1.455c-2.252 1.927-2.987 5.663-1.276 7.437s4.184-2.172 31.439 4c.056-1.053.419-1.514-1.1-2.792S67.287-1.242 61.49 1.455Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M61.488 1.458c-2.252 1.927-2.987 5.663-1.276 7.437s4.184-2.172 31.439 4c.056-1.053.419-1.514-1.1-2.792S67.285-1.239 61.488 1.458Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M96.026 4.922c-4.386 1.484-5.079 6.087-4.052 7.708s21.229 7.669 24.745 11.945a4.905 4.905 0 0 0 2.532-2.32c.101-.542-18.839-18.82-23.225-17.333Z"
        />
        <g filter="url(#m)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M96.031 4.927c-4.386 1.484-5.079 6.087-4.052 7.708s21.229 7.669 24.745 11.945a4.905 4.905 0 0 0 2.532-2.32c.101-.542-18.839-18.82-23.225-17.333Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M96.026 4.922c-4.386 1.484-5.079 6.087-4.052 7.708s21.229 7.669 24.745 11.945a4.905 4.905 0 0 0 2.532-2.32c.101-.542-18.839-18.82-23.225-17.333Z"
        />
      </g>
      <g data-type="innerShadowGroup">
        <path
          fill="#343e4f"
          d="M123.618 20.349c-.9-.566-4.525 1.454-6.635 5.081 11.773 7.06 19.4 17.488 20.417 17.914.427-1.268-7.777-22.048-13.782-22.995Z"
        />
        <g filter="url(#n)" transform="translate(.002 .001)">
          <path
            fill="#fff"
            d="M123.615 20.353c-.9-.566-4.525 1.454-6.635 5.081 11.773 7.06 19.4 17.488 20.417 17.914.427-1.268-7.777-22.048-13.782-22.995Z"
          />
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          d="M123.618 20.349c-.9-.566-4.525 1.454-6.635 5.081 11.773 7.06 19.4 17.488 20.417 17.914.427-1.268-7.777-22.048-13.782-22.995Z"
        />
      </g>
    </svg>
  );
}
