// my-theme.ts
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '5px',
  colors: {
    main: 'cyan',
    secondary: 'magenta',
  },
};

export { theme };
